import React from "react"
import { Flex, Box, Title, Image } from "../system"
import { urlFor } from "../../sanity"

const Profile = ({ data, sanity }) => {
  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-end"
      alignItems="flex-start"
      mb={4}
    >
      <Title
        fontWeight="bold"
        fontSize={[6, 7]}
        textAlign="left"
        color="primary"
        maxWidth={500}
      >
        {data.name}
      </Title>

      <Box width="100%">
        {data.avatar ? (
          <Image
            src={urlFor(data.avatar).width(500).height(500).quality(90).url()}
          />
        ) : null}
      </Box>
    </Flex>
  )
}

export default Profile
