import React, { useEffect, useState } from "react"
import { client } from "../../sanity"
import Layout from "../../components/Community/Layout"
import { Link } from "gatsby"
import slugify from "slugify"

import { Flex, Grid } from "../../components/system"
import Profile from "../../components/Community/profile"
import Loading from "../../components/Loading"

const Explore = () => {
  const [people, setPeople] = useState([])

  useEffect(() => {
    const query = '*[_type == "user" && !new]{_id, name, avatar}'

    client.fetch(query).then(data => {
      // setPeople(data[0])
      setPeople(data)
    })
  }, [])

  if (!people) {
    return <Loading />
  }

  return (
    <Layout>
      <Flex flexDirection="column">
        <Grid
          gridGap={4}
          gridTemplateColumns={["1fr", "1fr 1fr ", "1fr 1fr 1fr"]}
          mt={5}
          px={[3, 5]}
          width="100%"
        >
          {people.map((person, key) => (
            <Link
              key={key}
              to={`/community/people/${slugify(person.name, { lower: true })}`}
              state={{ id: person._id }}
              style={{ display: "contents" }}
            >
              <Profile data={person} />
            </Link>
          ))}
        </Grid>
      </Flex>
    </Layout>
  )
}

export default Explore
